import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints } from "~/styles/breakpoints"
import Container from "~/components/Container"
import Link from "~/components/Link"
import Button from "~/components/Button"
import { Columns, Column } from "~/components/Layout"
import { SecondaryTitle } from "~/components/Typography"
import ReactMarkdown from "react-markdown"

export default ({ title, text, buttonTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      alexImage: file(relativePath: { eq: "alex-2.jpg" }) {
        childImageSharp {
          fixed(width: 468, quality: 95) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Container>
        <Columns>
          <Column>
            <SecondaryTitle>{title}</SecondaryTitle>
            <Text>
              <ReactMarkdown children={text} />
            </Text>
            <ButtonContainer>
              <Button
                compact
                label={buttonTitle}
                icon="arrow-right"
                to="/about"
              />
            </ButtonContainer>
          </Column>
          <Column>
            <Illustration
              fixed={data.alexImage.childImageSharp.fixed}
              alt="Maky"
            />
          </Column>
        </Columns>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 130px;
`

const Text = styled.div`
  p {
    margin-bottom: 1em;
  }
`

const Illustration = styled(Img)`
  max-width: 100%;
  margin-top: -20px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    height: auto !important;

    img {
      position: static !important;

      &[aria-hidden] {
        display: none;
      }
    }
  }
`

const ButtonContainer = styled.p`
  margin-top: 40px;
`
