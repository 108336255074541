import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints, media } from "~/styles/breakpoints"
import Container from "~/components/Container"
import Button from "~/components/Button"
import { Columns, Column } from "~/components/Layout"
import ReactMarkdown from "react-markdown"

export default ({
  text,
  headline,
  secondaryText,
  excerptButtonTitle,
  excerptFile,
}) => {
  const data = useStaticQuery(graphql`
    query {
      obiImage: file(relativePath: { eq: "obi.jpg" }) {
        childImageSharp {
          fixed(width: 468, quality: 95) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Container>
        <Columns>
          <Column>
            <Text>
              <ReactMarkdown children={text} />
            </Text>
          </Column>
          <Column>
            <BookMockup src="/images/book-mockup.jpg" loading="lazy" />
          </Column>
        </Columns>
        <Headline>
          <ReactMarkdown
            children={headline}
            disallowedElements={["p"]}
            unwrapDisallowed
          />
        </Headline>
        <Columns>
          <Column>
            <Illustration
              fixed={data.obiImage.childImageSharp.fixed}
              alt="Obi"
            />
          </Column>
          <Column>
            <Text>
              <ReactMarkdown children={secondaryText} />
            </Text>
            <ButtonContainer>
              <Button
                outline
                label={excerptButtonTitle}
                icon="book-open"
                to={excerptFile}
                target="_blank"
              />
            </ButtonContainer>
          </Column>
        </Columns>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 100px;
  padding-top: 100px;
  position: relative;
  overflow: hidden;

  ${media.tablet} {
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      z-index: -1;
      pointer-events: none;
      background: center center / cover no-repeat;
    }

    &::before {
      width: 911px;
      height: 483px;
      margin-left: -1260px;
      top: 190px;
      background-image: url(/images/bg-food.jpg);
    }

    &::after {
      width: 1484px;
      height: 430px;
      margin-left: -20px;
      top: 540px;
      background-image: url(/images/bg-lemurs.jpg);
    }
  }

  ${media.tablet} {
    margin-bottom: 150px;
    padding-top: 150px;
  }
`

const Text = styled.div`
  p {
    margin-bottom: 1em;

    a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      transition: 0.15s;

      &:hover {
        color: #829794;
      }
    }
  }
`

const BookMockup = styled.img`
  width: 100%;
  image-rendering: -webkit-optimize-contrast;

  ${media.tablet} {
    width: 350px;
    margin-top: -30px;
    transform: rotate(3deg);
  }

  ${media.desktop} {
    margin-left: 50px;
  }
`

const Headline = styled.h2`
  max-width: 750px;
  margin: 0 auto 120px;
  background: -webkit-linear-gradient(#01947d, #395489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
`

const ButtonContainer = styled.p`
  margin-top: 40px;
`

const Illustration = styled(Img)`
  max-width: 100%;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    height: auto !important;

    img {
      position: static !important;

      &[aria-hidden] {
        display: none;
      }
    }
  }
`
