import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints, media } from "~/styles/breakpoints"
import Container from "~/components/Container"
import Button from "~/components/Button"
import { PrimaryTitle } from "~/components/Typography"
import ReactMarkdown from "react-markdown"

export default ({ title, subtitle, text, buttonTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "bg-donate.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Background
        fluid={data.bgImage.childImageSharp.fluid}
        style={{ position: "absolute" }}
      />
      <Container>
        <PrimaryTitle as="h2">{title}</PrimaryTitle>
        <Subtitle>{subtitle}</Subtitle>
        <Text>
          <ReactMarkdown children={text} />
        </Text>
        <ButtonContainer>
          <Button label={buttonTitle} icon="arrow-right" to="/books" />
        </ButtonContainer>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 100px;
  padding: 100px 0;
  position: relative;
  color: #fff;

  ${Container} {
    position: relative;
  }

  ${media.tablet} {
    margin-bottom: 150px;
  }
`

const Background = styled(Img)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  pointer-events: none;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    object-position: 70% 50%;
  }
`

const Subtitle = styled.h3`
  opacity: 0.6;
  margin-top: -35px;
  margin-bottom: 40px;
  font-size: 22px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    /* android hack */
    html:first-child & {
      margin-top: -10px;
    }
  }
`

const Text = styled.div`
  p {
    max-width: 470px;
    margin-bottom: 1em;

    a {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      transition: 0.15s;

      &:hover {
        color: #829794;
      }
    }
  }
`

const ButtonContainer = styled.p`
  margin-top: 52px;
`
