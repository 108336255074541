import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints, media } from "~/styles/breakpoints"
import Div100vh from "react-div-100vh"
import { animateScroll } from "react-scroll"
import Container from "~/components/Container"
import { PrimaryTitle } from "~/components/Typography"
import { ChevronDown } from "react-feather"

export default ({ title, subtitle }) => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "bg-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  function onButtonClick() {
    const scrollTop = Math.min(window.innerHeight, window.innerWidth * 0.7)
    animateScroll.scrollTo(scrollTop, {
      duration: 900,
      smooth: "easeInOutQuart",
    })
  }

  return (
    <Wrapper>
      <Background
        fluid={data.bgImage.childImageSharp.fluid}
        style={{ position: "absolute" }}
      />
      <Container>
        <PrimaryTitle style={{ maxWidth: "8em" }}>{title}</PrimaryTitle>
        <Subtitle>{subtitle}</Subtitle>
        <ScrollIndicator onClick={onButtonClick}>
          <ChevronDown size={24} style={{ fill: "none" }} />
        </ScrollIndicator>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(Div100vh)`
  height: 100vh;
  position: relative;
  color: #fff;

  ${Container} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 55px;
    position: relative;

    @media (min-height: 800px) {
      padding-bottom: 75px;
    }
  }

  ${media.tablet} {
    max-height: 70vw;
  }
`

const Background = styled(Img)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  pointer-events: none;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    img {
      object-position: 25% 50% !important;
    }
  }
`

const Subtitle = styled.p`
  opacity: 0.7;
  max-width: 330px;
`

const ScrollIndicator = styled.button`
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  width: 50px;
  height: 50px;
  padding-top: 4px;
  position: absolute;
  bottom: 56px;
  left: 420px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.15s;

  &:hover {
    opacity: 1;
  }

  @media (min-height: 800px) {
    bottom: 76px;
  }

  ${media.tablet} {
    display: inline-flex;
  }
`
