import React from "react"
import SEO from "~/components/seo"
import Header from "~/components/Header"
import Hero from "~/components/Hero"
import SectionAbout from "~/components/SectionAbout"
import SectionDonate from "~/components/SectionDonate"
import SectionAuthor from "~/components/SectionAuthor"
import pageData from "../../content/pages/index.json"

export default () => {
  const {
    metaTitle,
    metaDescription,
    metaImage,
    title,
    subtitle,
    intro,
    introHighlighted,
    introSecondary,
    excerptButtonTitle,
    excerptFile,
    donationTitle,
    donationSubtitle,
    donationText,
    donationButtonTitle,
    authorTitle,
    authorText,
    authorButtonTitle,
  } = pageData

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} image={metaImage} />
      <Header pathname="/" />
      <Hero title={title} subtitle={subtitle} />
      <SectionAbout
        text={intro}
        headline={introHighlighted}
        secondaryText={introSecondary}
        excerptButtonTitle={excerptButtonTitle}
        excerptFile={excerptFile}
      />
      <SectionDonate
        title={donationTitle}
        subtitle={donationSubtitle}
        text={donationText}
        buttonTitle={donationButtonTitle}
      />
      <SectionAuthor
        title={authorTitle}
        text={authorText}
        buttonTitle={authorButtonTitle}
      />
    </>
  )
}
